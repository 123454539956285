import { Box, Heading, Text, VStack, Image, Center, useBreakpointValue } from '@chakra-ui/react';
import { useSpring, animated } from '@react-spring/web';
import avatar from '../images/avatar.png';
import TypewriterText from '../components/TypeWriterText';

const Home = () => {
  const imgSize = useBreakpointValue({ base: "10rem", md: "14rem" }); // Set base and md values
  const mobileMargin = useBreakpointValue({ base: "20px", md: "40px" }); // Set margin for mobile and desktop
  const xSpring = useBreakpointValue({ base: 100, md: 200 });
  const springs = useSpring({
    from: { x: 0 },
    to: { x: xSpring }
  });

  const content = [
    "On this site, you'll find the blog and portfolio of Alyssa's projects. Although these projects are not always centered around the same genre of interest, there is hope that there will be enough spark in the content provided to earn even the slightest bit of your attention. If you have any project ideas or insight, feel free to reach out to Alyssa via the contact links on the resume page.",
    "\n\n",
    "As a forewarning, within the blog section, you'll find entries on various subjects such as art, literature, philosophy, and potentially Buddhism. Though, in the end, they will all point back to the main subject. Coding, tech, and creating a whole lot of something out of 1s and 0s."
  ].join('');

  return (
    <VStack spacing={6} align="start" justify="center" padding={{ base: "10px 20px 40px", md: "40px" }}>
      <Center w="full">
        <Box mb={mobileMargin}>
          <Heading as="h1" fontSize={{ base: "24px", md: "32px" }}>Alyssa's Portfolio & Blog</Heading>
        </Box>
      </Center>
      <animated.div style={{ ...springs }}>
        <Image 
          src={avatar} 
          alt="avatar" 
          boxSize={imgSize} 
          objectFit="cover"
          display="block"
          marginLeft="auto"
          marginRight="auto"
        />
      </animated.div>

      <Text 
        textAlign={{ base: "justify", md: "left" }}
        mt={{ base: "4", md: "2" }} // Increase margin-top for mobile
        mb={{ base: "4", md: "2" }} // Increase margin-bottom for mobile
        lineHeight="1.5"
        textIndent='2rem'
      >
          <TypewriterText content= { content } />
      </Text>
    </VStack>
  );
};

export default Home;
