import { extendTheme } from '@chakra-ui/react';

const theme = extendTheme({
  fonts: {
    body: "'Space Grotesk', sans-serif",
    heading: "'Space Grotesk', sans-serif",
    // Add any other font styles here if needed
  },
});

export default theme;