import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import {
    Center,
    Spinner,
    IconButton,
    Link,
    Heading,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
    VStack,
    InputGroup,
    InputRightElement,
    Input,
    Button
} from '@chakra-ui/react';
import { FaGithub, FaLinkedin, FaTwitter, FaPatreon, FaSearch } from 'react-icons/fa';

const Resume = () => {
    const [searchedTechnology, setSearchedTechnology] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const searchRef = useRef(null);
    const [experiences, setExperiences] = useState([]);
    const [loading, setLoading] = useState(true);
    const mobileMargin = useBreakpointValue({ base: "20px", md: "40px" });

    const handleInputChange = (event) => {
        setSearchTerm(event.target.value);
    }

    const handleSearch = (event) => {
        if (event.key === 'Enter' || event.type === 'click') {
            setSearchedTechnology(searchTerm);
        }
    }

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/experiences');
                setExperiences(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    useEffect(() => {
        if (searchedTechnology) {
            const fetchData = async () => {
                try {
                    setLoading(true);
                    const response = await axios.get(`/api/experiences?technology=${searchedTechnology}`);
                    setExperiences(response.data);
                    setLoading(false);
                } catch (error) {
                    console.error(error);
                    setLoading(false);
                }
            };

            fetchData();
        }
    }, [searchedTechnology]);

    if (loading) {
        return <Center h="100vh"><Spinner /></Center>;
    }

    return (
        <>
            <VStack spacing={6} align="start" padding={{ base: "20px", md: "40px" }}>
                <Center w='full'>
                    <Heading as='h1' mb={mobileMargin}>Resume</Heading>
                </Center>

                <Text align="center" lineHeight="1.5">
                    Alyssa Glasco is a Senior Full stack engineer successfully performing in both individual as well as leadership technical roles. Excellent in all phases of system development: analysis, architectural design, UI and UX design, programming, quality assurance, modeling, technical documentation, and training.
                </Text>
                <Center width='full' mb={mobileMargin}>
                    <Link mr={2} href='https://x.com/Alyce_Rabyte' isExternal target="_blank" rel="noopener noreferrer">
                        <IconButton colorScheme="blue" icon={<FaTwitter />} aria-label="Visit Twitter profile" />
                    </Link>
                    <Link mr={2} href='https://github.com/spiffai' isExternal target="_blank" rel="noopener noreferrer">
                        <IconButton colorScheme="pink" icon={<FaGithub />} aria-label="Visit GitHub profile" />
                    </Link>
                    <Link mr={2} href='https://www.linkedin.com/in/alyssa-glasco-83525925' isExternal target="_blank" rel="noopener noreferrer">
                        <IconButton colorScheme="blue" icon={<FaLinkedin />} aria-label="Visit LinkedIn profile" />
                    </Link>
                    <Link href='https://www.patreon.com/spiffai' isExternal target="_blank" rel="noopener noreferrer">
                        <IconButton colorScheme="pink" icon={<FaPatreon />} aria-label="Visit Patreon profile" />
                    </Link>
                </Center>
            </VStack>

            <InputGroup size='md'>
                <Input
                    ref={searchRef}
                    placeholder="Search skills..."
                    value={searchTerm}
                    onChange={handleInputChange}
                    onKeyDown={handleSearch}
                />
                <InputRightElement width='4.5rem'>
                    <Button h='1.75rem' size='sm' colorScheme='pink' onClick={handleSearch}><FaSearch /></Button>
                </InputRightElement>
            </InputGroup>

            <Accordion allowToggle>
                {experiences
                    .filter((experience) =>
                        experience.technologies.toLowerCase().includes(searchedTechnology.toLowerCase())
                    )
                    .map((experience) => (
                        <AccordionItem key={experience.name}>
                            <h2>
                                <AccordionButton>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <Heading as='h2' className="experience-list-item-company">
                                            <a href={experience.url} target="_blank" rel="noreferrer" className="experience-list-item-company">{experience.company}</a></Heading>
                                        <Heading as='h2' className="experience-list-item-title">{experience.title}</Heading>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Text className="dates-subtext">({experience.dates}) </Text>
                                <Text>
                                    {experience.description}
                                </Text>
                                <Text className="small-text">
                                    <u>Technologies:</u> {experience.technologies}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
            </Accordion>
        </>
    );
};

export default Resume;
