import { useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import {
    Box,
    Heading,
    VStack,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Button,
    Text,
    Link,
    useToast,
} from "@chakra-ui/react";


const SignIn = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const signIn = async () => {
        try {
            await signInWithEmailAndPassword(getAuth(), email, password);
            navigate('/articles');
        } catch (e) { //TODO: user friendly errors
            toast({
                title: 'Sign In Error',
                description: error.message,
                status: "error",
                duration: 3000,
                isClosable: true,
            });
            // TODO: Might not need this anymore...
            setError(e.message);
        }
    }

    return (
        <>
            <Box textAlign='center' mt={8} maxW='md' mx='auto'>
                <Heading mb={6}>Sign In</Heading>
                <VStack spacing={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor='email'>Email</FormLabel>
                        <Input
                            id='email'
                            type='email'
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder='snake@forceoperationx.com'
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor='password'>Password</FormLabel>
                        <InputGroup size='md'>
                            <Input
                                id='password'
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder='password...'
                            />
                            <InputRightElement width='auto'>
                                <IconButton 
                                    size='md' 
                                    icon={showPassword ? <ViewOffIcon color="pink"/> : <ViewIcon color="pink"/>} 
                                    variant='ghost'
                                    onClick={() => setShowPassword(!showPassword)} />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Button colorScheme='pink' onClick={signIn}>
                        Log In
                    </Button>
                </VStack>
                <Text mt={6} color='gray.500'>
                    Don't have an account? Create one{" "}
                    <Link as={RouterLink} to='/signup' color='teal.500'>
                        here.
                    </Link>
                </Text>
            </Box>
        </>
    );
}

export default SignIn;