import { Box, Heading, Text, Card, CardBody, CardHeader, Stack, StackDivider } from "@chakra-ui/react";

const CommentsList = ({ comments }) => (
    <>
        <Card>
            <CardHeader>
                <Heading as="h3" size="md">
                    Comments
                </Heading>
            </CardHeader>

            <CardBody>
                <Stack divider={<StackDivider />} spacing='4'>
                    {comments.map((comment) => (
                        <Box key={comment.postedBy + ': ' + comment.text} mb={4}>
                            <Heading size='xs' textTransform='uppercase'>
                            {comment.postedBy}
                            </Heading>
                            <Text pt='2' fontSize='sm'>
                            {comment.text}
                            </Text>
                        </Box>
                    ))}
                </Stack>
            </CardBody>
        </Card>

    </>
)

export default CommentsList;