import { Box, Flex, IconButton, Button, useDisclosure, Drawer, DrawerBody, DrawerFooter, DrawerHeader, DrawerOverlay, DrawerContent, DrawerCloseButton, Link as ChakraLink } from '@chakra-ui/react';
import { HamburgerIcon } from '@chakra-ui/icons';
import logo from './images/logo.png';
import { Link, useNavigate } from 'react-router-dom'
import { getAuth, signOut } from 'firebase/auth'
import useUser from './hooks/useUser';
import { FaPizzaSlice, FaDiceD20, FaEmpire, FaFeather, FaCodeBranch } from 'react-icons/fa';

const NavBar = () => {
  const navigate = useNavigate()
  const { user } = useUser()
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Box bg="blue.100" color="white" p={4} position="relative">
        <Flex justifyContent="center" alignItems="center">
          {/* Logo */}
          <Box>
            <Link to="/">
              <ChakraLink color="white" fontSize="xl" fontWeight="bold">
                <img src={logo} className="logo" alt="logo" />
              </ChakraLink>
            </Link>
          </Box>
        </Flex>

        {/* Hamburger menu */}
        <IconButton
          aria-label="Open menu"
          icon={<HamburgerIcon />}
          onClick={onOpen}
          position="absolute"
          bottom="8px"
          right="8px"
          bg="#14436B"
          color="#0FB3D3"
          _hover={{ bg: "#0FB3D3", color: "#14436B" }}
          _active={{ bg: "#14436B", color: "#0FB3D3" }}
        />

        {/* Drawer for Both Desktop and Mobile */}
        <Drawer isOpen={isOpen} placement="right" onClose={onClose}>
          <DrawerOverlay>
            <DrawerContent className="drawer-content">
              <DrawerCloseButton color="pink" />
              <DrawerHeader className="drawer-header">Navigation</DrawerHeader>
              <DrawerBody>
                <Flex flexDirection="column" gap={4}>
                  <Link to="/">
                    <Flex alignItems="center">
                      <FaPizzaSlice color="pink" />
                      <ChakraLink ml={3} onClick={onClose} className="drawer-header">
                        Home
                      </ChakraLink>
                    </Flex>
                  </Link>
                  <Link to="/about">
                    <Flex alignItems="center">
                      <FaEmpire color="pink" />
                      <ChakraLink ml={3} onClick={onClose} className="drawer-header">
                        About
                      </ChakraLink>
                    </Flex>
                  </Link>
                  <Link to="/articles">
                    <Flex alignItems="center">
                      <FaFeather color="pink" />
                      <ChakraLink ml={3} onClick={onClose} className="drawer-header">
                        Articles
                      </ChakraLink>
                    </Flex>
                  </Link>
                  <Link to="/resume">
                    <Flex alignItems="center">
                      <FaDiceD20 color="pink" />
                      <ChakraLink ml={3} onClick={onClose} className="drawer-header">
                        Resume
                      </ChakraLink>
                    </Flex>
                  </Link>
                  <Link to="/projects">
                    <Flex alignItems="center">
                      <FaCodeBranch color="pink" />
                      <ChakraLink ml={3} onClick={onClose} className="drawer-header">
                        Projects
                      </ChakraLink>
                    </Flex>
                  </Link>
                </Flex>
              </DrawerBody>
              <DrawerFooter>
                <Flex justifyContent="center" flexDirection="column" gap={4}>
                  {user ? (
                    <Button colorScheme="pink" onClick={() => { signOut(getAuth()); onClose(); }}>Sign Out</Button>
                  ) : (
                    <Button colorScheme="pink" onClick={() => { navigate('/signin'); onClose(); }}>Sign In</Button>
                  )}
                </Flex>
              </DrawerFooter>
            </DrawerContent>
          </DrawerOverlay>
        </Drawer>
      </Box>
    </>
  )
}

export default NavBar;