import taylor from '../images/taylor-idle.gif';
import { Heading, Text, Center, VStack, useBreakpointValue } from '@chakra-ui/react';
import TypewriterText from '../components/TypeWriterText';

const About = () => {
    const imgSize = useBreakpointValue({ base: "10rem", md: "20rem" }); // Set base and md values
    const mobileMargin = useBreakpointValue({ base: "20px", md: "40px" }); // Set margin for mobile and desktop
    const content = [
        "Growing up with an early interest in computer science, technology, and the arts, Alyssa has 10+ years experience working in the tech industry. As we all know, most developers don't stop coding once they clock out for the day. So, Alyssa has accumulated various projects over the years.",
        "\n\n",
        "Alyssa's projects contain multitudes. We can break that  down into meaning whatever she currently finds to be \"interesting\". With that, these can span from video games, motion art, chrome extensions, to AR/VR experiences. She doesn't yet have the projects portion of this website completed. Until then, feel free to check out her github profile. A link to her github is located within the resume section of this site."
      ].join('');
    return (
        <VStack spacing={6} align="start" px={mobileMargin} py={{ base: mobileMargin, md: "40px" }}>  {/* Add margins and padding */}
            <Center w='full'>
                <Heading as='h1'>Who's Alyssa?</Heading>
            </Center>
            <Center w='full'>
                <img src={taylor}
                    alt="taylor"
                    boxSize={imgSize}
                    objectFit="cover"
                    display="block"
                    marginLeft="auto"
                    marginRight="auto"
                />
            </Center>
            <Text textIndent='2rem'>
                <TypewriterText content={content} />
            </Text>
        </VStack>
    );
};

export default About;
