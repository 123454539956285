// TypewriterText.js
import React, { useEffect, useState } from 'react';
import { useSpring, animated, config } from '@react-spring/web';

const TypewriterText = ({ content, delay = 0, speed = 50 }) => {
  const [displayText, setDisplayText] = useState('');
  const [index, setIndex] = useState(0);

  useEffect(() => {
    let timeout;
    if (index < content.length) {
      timeout = setTimeout(() => {
        setDisplayText((prev) => prev + content[index]);
        setIndex((prev) => prev + 1);
      }, speed);
    }
    return () => clearTimeout(timeout);
  }, [index, content, speed]);

  const textSpring = useSpring({
    opacity: 1,
    transform: 'translateY(0)',
    from: { opacity: 0, transform: 'translateY(-20px)' },
    config: config.molasses,
    delay
  });

  return (
    // TODO: add in ability to stop animation and load full text w/ button
    <animated.div style={{ ...textSpring, whiteSpace: 'pre-wrap' }}>
      {displayText}
    </animated.div>
  );
};

export default TypewriterText;
