import React, { useState, useEffect } from 'react';
import taylor from '../images/Taylor-Hide-Eyes.gif';
import axios from 'axios';
import {
    Center,
    Spinner,
    Heading,
    Text,
    Accordion,
    AccordionItem,
    AccordionButton,
    Box,
    AccordionPanel,
    AccordionIcon,
    useBreakpointValue,
    VStack,
} from '@chakra-ui/react';

const Resume = () => {
    const [projects, setProjects] = useState([]);
    const [loading, setLoading] = useState(true);
    const mobileMargin = useBreakpointValue({ base: "20px", md: "40px" });
    const imgSize = useBreakpointValue({ base: "10rem", md: "20rem" }); // Set base and md values

    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/projects');
                setProjects(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
            }
        };

        fetchData();
    }, []);

    if (loading) {
        return <Center h="100vh"><Spinner /></Center>;
    }

    return (
        <>
            <VStack spacing={6} align="start" padding={{ base: "20px", md: "40px" }}>
                <Center w='full'>
                    <Heading as='h1' mb={mobileMargin}>Projects</Heading>
                </Center>

                <Center w='full'>
                    <img src={taylor}
                        alt="taylor"
                        boxSize={imgSize}
                        objectFit="cover"
                        display="block"
                        marginLeft="auto"
                        marginRight="auto"
                    />
                </Center>

                <Center>
                    <Text align="center" lineHeight="1.5">
                        Here is a listing of all the current and previous projects created by, lead by, or using heavy contributions from Alyssa Glasco. Some of these products are owned by her explicitly while others were either completed under full time employment or contract.
                    </Text>
                </Center>
            </VStack>

            <Accordion allowToggle>
                {projects
                    .map((project) => (
                        <AccordionItem key={project.slug}>
                            <h2>
                                <AccordionButton>
                                    <Box as='span' flex='1' textAlign='left'>
                                        <Heading as='h2' className="experience-list-item-company">
                                            <a href={project.url} target="_blank" rel="noreferrer" className="experience-list-item-company">{project.title}</a>
                                        </Heading>
                                    </Box>
                                    <AccordionIcon />
                                </AccordionButton>
                            </h2>
                            <AccordionPanel pb={4}>
                                <Text>
                                    {project.description}
                                </Text>
                            </AccordionPanel>
                        </AccordionItem>
                    ))}
            </Accordion>
        </>
    );
};

export default Resume;
