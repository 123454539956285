import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import useUser from '../hooks/useUser';
import { Heading, Box, FormControl, FormLabel, Input, Button } from '@chakra-ui/react';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const ArticleForm = () => {
    const [title, setTitle] = useState('');
    const [content, setContent] = useState('');
    const { articleId } = useParams();
    const { user, userRole } = useUser();
    const navigate = useNavigate();

    if (userRole && userRole !== 'admin') {
        console.log('User Role: ', userRole);
        navigate('/notfound');
    }

    const modules = {
        toolbar: [
            [{ 'header': [1, 2, false] }],
            ['bold', 'italic', 'underline', 'strike', 'blockquote'],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
            ['link', 'image'],
            ['clean']
        ],
    };

    const formats = ['header', 'bold', 'italic', 'underline', 'strike', 'blockquote', 'list', 'bullet', 'indent', 'link', 'image'];

    useEffect(() => {
        const fetchArticle = async () => {
            if (articleId) {
                const response = await axios.get(`/api/articles/${articleId}`);
                const article = response.data;
                setTitle(article.title);
                setContent(article.content);
            }
        };
        fetchArticle();
    }, [articleId]);

    const handleSubmit = async (e) => {
        e.preventDefault();
        const data = { title, content };
        const token = user && await user.getIdToken();
        const headers = token ? { authtoken: token } : {};

        try {
            if (articleId) {
                await axios.put(`/api/articles/${articleId}`, data);
            } else {
                await axios.post('/api/articles', data, { headers });
            }
            const slug = title.toLowerCase().replace(/[^\w-]/g, '-').trim();

            navigate(`/articles/${articleId || slug}`); // Handle both create and edit scenarios for navigation
        } catch (error) {
            console.error(error);
            // Handle errors appropriately (e.g., display error message to user)
        }
    };

    return (
        <>
            {userRole === 'admin' ? (

                <Box as="form" onSubmit={handleSubmit}>
                    <Heading as='h1'>{articleId ? 'Edit Article' : 'Create Article'}</Heading>
                    <FormControl mb={8}>
                        <FormLabel htmlFor="title">Title:</FormLabel>
                        <Input type="text" id="title" value={title} onChange={(e) => setTitle(e.target.value)} required />
                    </FormControl>
                    <FormControl mb={8}>
                        <FormLabel htmlFor="content">Content:</FormLabel>
                        <ReactQuill
                            theme="snow" // Specify theme (optional)
                            modules={modules}
                            formats={formats}
                            value={content}
                            onChange={(e) => setContent(e)} // Update content state on change
                        />
                    </FormControl>
                    <Button type="submit" colorScheme="blue">{articleId ? 'Update' : 'Create'}</Button>
                </Box>
            ) : (
                <div></div>
            )}
        </>
    );
};

export default ArticleForm;
