import ArticlesList from '../components/ArticlesList';
import { VStack, Heading, Button, Center, useBreakpointValue } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import taylor from '../images/Taylor-Walking.gif';
import useUser from '../hooks/useUser';

const Articles = () => {
    const navigate = useNavigate();
    const { userRole } = useUser();
    const mobileMargin = useBreakpointValue({ base: "20px", md: "40px" }); // Set margin for mobile and desktop
    const imgSize = useBreakpointValue({ base: "20px", md: "40px" }); // Set base and md values
    
    const handleCreateArticle = () => {
        navigate('/articles/create');
    };

    return (
        <>
            <VStack spacing={6} align="start" px={mobileMargin} py={{ base: mobileMargin, md: "40px" }}>
                <Center w='full'><Heading as="h1" mb={mobileMargin}> Articles </Heading></Center>
                <Center w='full'>
                <img src={taylor}
                    alt="taylor"
                    boxSize={imgSize}
                    objectFit="cover"
                    display="block"
                    marginLeft="auto"
                    marginRight="auto"
                />
            </Center>
                
                {userRole === 'admin' ? (
                    <Button mr={2} colorScheme='pink' onClick={handleCreateArticle}>Create Article</Button>
                ) : (
                    <div></div>
                )}
                <ArticlesList />
            </VStack>
        </>
    )
};

export default Articles;