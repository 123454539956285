import { useState } from "react";
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { getAuth, createUserWithEmailAndPassword } from 'firebase/auth';
import { useToast } from "@chakra-ui/react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import axios from "axios";
import {
    Box,
    Heading,
    VStack,
    FormControl,
    FormLabel,
    Input,
    InputGroup,
    InputRightElement,
    IconButton,
    Button,
    Text,
    Link,
} from "@chakra-ui/react";

const SignUp = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [confirm, setConfirm] = useState('');
    const [error, setError] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [showConfirm, setShowConfirm] = useState(false);

    const navigate = useNavigate();
    const toast = useToast();

    const signUp = async () => {
        try {
            if (password !== confirm) {
                setError('Password entries do not match.')
                toast({
                    title: 'Sign Up Error',
                    description: error,
                    status: 'error',
                    duration: 3000,
                    isClosable: true,

                });
                return;
            }

            createUserWithEmailAndPassword(getAuth(), email, password)
            .then((userCredential) => {
              const user = userCredential.user;
              const uid = user.uid;
          
              // Prepare data object with email and uid
              const data = { email, uid };
          
              // Make the Axios call after user creation
              return axios.post('/api/users', data);
            })
            .then((response) => {
              // Handle successful response from the API
              navigate('/articles');
            })
            .catch((error) => {
              // Handle errors during user creation or API call
              console.error("Error creating user or sending data:", error);
              setError('Error creating user in database.');
              toast({
                  title: 'Sign Up Error',
                  description: 'Error creating user in database.',
                  status: 'error',
                  duration: 3000,
                  isClosable: true,
              });
            });
        } catch (e) {
            setError(e.message);
            toast({
                title: 'Sign Up Error',
                description: e.message,
                status: 'error',
                duration: 3000,
                isClosable: true,
            });
        }
    }

    return (
        <>
            <Box textAlign="center" mt={8} maxW="md" mx="auto">
                <Heading mb={6}>Sign Up</Heading>
                <VStack spacing={4}>
                    <FormControl isRequired>
                        <FormLabel htmlFor="email">Email</FormLabel>
                        <Input
                            id="email"
                            type="email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            placeholder="snake@forceoperationx.com"
                        />
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor="password">Password</FormLabel>
                        <InputGroup size="md">
                            <Input
                                id="password"
                                type={showPassword ? 'text' : 'password'}
                                value={password}
                                onChange={(e) => setPassword(e.target.value)}
                                placeholder="password..."
                            />
                            <InputRightElement width='auto'>
                                <IconButton 
                                    size='md' 
                                    icon={showPassword ? <ViewOffIcon color="pink"/> : <ViewIcon color="pink"/>} 
                                    variant='ghost'
                                    onClick={() => setShowPassword(!showPassword)} />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <FormControl isRequired>
                        <FormLabel htmlFor="confirm">Confirm Password</FormLabel>
                        <InputGroup size="md">
                            <Input
                                id="confirm"
                                type={showConfirm ? 'text' : 'password'}
                                value={confirm}
                                onChange={(e) => setConfirm(e.target.value)}
                                placeholder="confirm..."
                            />
                            <InputRightElement width='auto'>
                                <IconButton 
                                    size='md' 
                                    icon={showConfirm ? <ViewOffIcon color="pink"/> : <ViewIcon color="pink"/>} 
                                    variant='ghost'
                                    onClick={() => setShowConfirm(!showConfirm)} />
                            </InputRightElement>
                        </InputGroup>
                    </FormControl>
                    <Button colorScheme="pink" onClick={signUp}>
                        Sign Up
                    </Button>
                </VStack>
                <Text mt={6} color="gray.500">
                    Have an account? Log in{" "}
                    <Link as={RouterLink} to="/signin" color="teal.500">
                        here.
                    </Link>
                </Text>
            </Box>
        </>
    );
}

export default SignUp;