import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link as RouterLink } from 'react-router-dom';
import { Stack, Link, Heading, Text, Spinner, Center } from '@chakra-ui/react';

const ArticlesList = () => {
    const [articles, setArticles] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch articles on component mount
    useEffect(() => {
        const fetchData = async () => {
            try {
                setLoading(true);
                const response = await axios.get('/api/articles'); // Replace with your actual API endpoint URL
                setArticles(response.data);
                setLoading(false);
            } catch (error) {
                console.error(error);
                setLoading(false);
                // Handle errors appropriately (e.g., display an error message)
            }
        };

        fetchData();
    }, []);

    if(loading) {
        return<Center><Spinner /></Center>
    }

    return (
        <>
            {articles.sort((a, b) => new Date(b.createDate) - new Date(a.createDate)).map((article) => (
                <div key={article.name}>
                    <Stack m={0} spacing={2}>
                        <Link as={RouterLink} color="teal.500" to={`/articles/${article.name}`}>
                            <Heading as="h5" size="md">
                                {article.title}
                            </Heading>
                        </Link>
                        <Text className="article-list-date">{article.createDate}</Text>
                        <div dangerouslySetInnerHTML={{ __html: article.content.substring(0, 150) + '...' }} />
                    </Stack>
                </div>
            ))}
        </>
    )
}

export default ArticlesList;