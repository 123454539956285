import taylor from '../images/Taylor-Walking.gif';

const NotFound = () => {
    return(
        <>
        <img src={taylor} className="avatar" alt="logo" />
        <h1>404: Page Not Found!</h1>
        </>
    )
}

export default NotFound;