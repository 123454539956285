import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import axios from 'axios';

const useUser = () => {
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);
    const [userRole, setUserRole] = useState('');

    useEffect(() => {
        const unsubscribe = onAuthStateChanged(getAuth(), async (user) => {
            setUser(user);
            if(user) {
                try {
                    const response = await axios.get(`/api/user`, {
                        headers: {
                            authtoken: await user.getIdToken()
                        }
                    });
                    setUserRole(response.data.role);
                } catch (e){
                    console.error('Error fetching user: ', e);
                }
            }
            setIsLoading(false);
        });

        return unsubscribe;
    }, []);

    return { user, isLoading, userRole };
}

export default useUser;