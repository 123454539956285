import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { StarIcon } from '@chakra-ui/icons';
import {
    Box,
    Heading,
    Divider,
    Button,
    Flex,
    Link,
    Popover,
    PopoverTrigger,
    PopoverContent,
    PopoverBody,
    Portal,
    PopoverArrow,
    useToast,
    Spinner,
    Center
} from "@chakra-ui/react";
import axios from 'axios';
import AddCommentForm from '../components/AddCommentForm';
import useUser from '../hooks/useUser';
import CommentsList from '../components/CommentsList';
import ReactQuill from 'react-quill';

const Article = () => {
    const [articleInfo, setArticleInfo] = useState({ upvotes: 0, comments: [], canUpvote: false });
    const [loading, setLoading] = useState(false);
    const { canUpvote } = articleInfo;
    const { articleId } = useParams();
    const { user, isLoading, userRole } = useUser();
    const navigate = useNavigate();
    const toast = useToast();

    useEffect(() => {        
        const loadArticleInfo = async () => {
            setLoading(true);
            const token = user && await user.getIdToken();
            const headers = token ? { authtoken: token } : {};
            const response = await axios.get(`/api/articles/${articleId}`, { headers });
            const newArticleInfo = response.data;
            setArticleInfo(newArticleInfo);
            setLoading(false);
        };

        if (!isLoading) {
            loadArticleInfo();
        }

    }, [isLoading, user, articleId]);

    const addUpvote = async () => {
        setLoading(true);
        const token = user && await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        const response = await axios.put(`/api/articles/${articleId}/upvote`, null, { headers }); // null is the req body for a put
        const updatedArticle = response.data;
        setArticleInfo(updatedArticle);
        setLoading(false);
    };

    const handleCreateArticle = () => {
        navigate('/articles/create');
      };
      
    const handleEditArticle = () => {
        navigate(`/articles/${articleId}/edit`); // Edit route with articleId
      };

    const handleDelete = async () => {
        try {
          const response = await fetch(`/api/articles/${articleId}`, {
            method: 'DELETE',
          });
          if (response.ok) {
            navigate('/articles');
          } else {
            toast({
                title: 'We Hit a Problem...',
                description: 'There was an error in deleting this article.',
                status: "error",
                duration: 3000,
                isClosable: true,
            });
          }
        } catch (error) {
          toast({
            title: 'We Hit a Problem...',
            description: error.message,
            status: "error",
            duration: 3000,
            isClosable: true,
        });
        }
      };

      const renderAdminButtons = () => (
        <>
            <Button mr={2} colorScheme='yellow' onClick={handleCreateArticle}>Create Article</Button>
            <Button mr={2} colorScheme='teal' onClick={handleEditArticle}>Edit Article</Button>
            <Button mr={2} colorScheme='orange' onClick={() => handleDelete()}>Delete Article</Button>
        </>
    );
    
    if (!articleInfo || loading) {
        return <Center><Spinner /></Center>
    }

    return (
        <>
            <Box maxW="7xl" mx="auto" py={8}>
                <Heading as="h1" size="lg">
                    {articleInfo.title}
                </Heading>
                <Box mt={4} mb={8}>
                    <ReactQuill 
                        value={articleInfo.content}
                        readOnly={true}
                        theme={"bubble"}/>
                </Box>
                <Divider my={8} />
                <Flex justifyContent="flex-end" alignItems="center">
                    {user ? (
                        <div>
                            {userRole === 'admin' && renderAdminButtons()}
                            <Button colorScheme="blue" onClick={addUpvote} disabled={!canUpvote}>
                                <span style={{ display: 'flex', alignItems: 'center' }}>
                                    {articleInfo.upvotes}
                                    <StarIcon color='yellow.500' style={{ marginLeft: 5 }} /> {/* Adjust margin as needed */}
                                </span>
                            </Button>
                        </div>
                    ) : (
                        <Popover
                            placement='bottom'>
                            <PopoverTrigger>
                                <Button disabled='true'>
                                    <span style={{ display: 'flex', alignItems: 'center' }}>
                                        {articleInfo.upvotes}
                                        <StarIcon color='yellow.500' style={{ marginLeft: 5 }} /> {/* Adjust margin as needed */}
                                    </span>
                                </Button>
                            </PopoverTrigger>
                            <Portal>
                                <PopoverContent color='white' bg='teal.400' borderColor='teal.400'>
                                    <PopoverArrow bg='teal.400' />
                                    <PopoverBody alignItems='center'>
                                        You must <Link as={RouterLink} to='/signin' color='black'>login</Link> to vote!
                                    </PopoverBody>
                                </PopoverContent>
                            </Portal>
                        </Popover>
                    )}
                </Flex>
                {user && <AddCommentForm articleName={articleId} onArticleUpdated={setArticleInfo} />}
                <CommentsList comments={articleInfo.comments} />
            </Box>
        </>
    )
}

export default Article;