import { useState } from 'react';
import axios from 'axios';
import useUser from '../hooks/useUser';
import {
    Box,
    Text,
    VStack,
    FormControl,
    FormLabel,
    Textarea,
    Button,
    Flex,
} from "@chakra-ui/react";

const AddCommentForm = ({ articleName, onArticleUpdated }) => {
    const [name, setName] = useState('');
    const [commentText, setCommentText] = useState('');
    const { user } = useUser();

    const addComment = async () => {
        const token = user && await user.getIdToken();
        const headers = token ? { authtoken: token } : {};
        const response = await axios.post(`/api/articles/${articleName}/comments`, {
            postedBy: name,
            text: commentText,
        }, {
            headers,
        });
        const updatedArticle = response.data;
        onArticleUpdated(updatedArticle);
        setName('');
        setCommentText('');
    };

    return (
        <>
            <Box mb={8} mt={8}>
                <VStack spacing={4}>
                    <FormControl>
                        <FormLabel htmlFor="comment">Comment</FormLabel>
                        <Textarea
                            id="comment"
                            value={commentText}
                            onChange={(e) => setCommentText(e.target.value)}
                            placeholder="Write your comment..."
                            rows={4}
                        />
                        <Flex justifyContent="flex-end" alignItems="center">
                            {user && (<Text as='sup' justifyContent='flex-end'>You are posting as {user.email.substring(0, user.email.indexOf("@"))}</Text>)}
                        </Flex>
                        
                    </FormControl>
                    <Button colorScheme="teal" onClick={addComment}>
                        Add Comment
                    </Button>
                </VStack>
            </Box>
        </>
    )

}

export default AddCommentForm;