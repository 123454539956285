import './App.css';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import NavBar from './NavBar';
import Home from './pages/Home';
import SignUp from './pages/SignUp';
import SignIn from './pages/SignIn';
import About from './pages/About';
import Articles from './pages/Articles';
import Article from './pages/Article';
import ArticleForm from './pages/ArticleForm';
import Resume from './pages/Resume';
import Projects from './pages/Projects';
import NotFound from './pages/NotFound';

function App() {
  return (
    <BrowserRouter>
      <div className="App">
        <NavBar />
        <div id="page-body">
          <Routes>
            <Route path="/" element={ <Home />} />
            <Route path="/signin" element={<SignIn />} />
            <Route path="/signup" element={<SignUp />} />
            <Route path="/about" element={ <About />} />
            <Route path="/resume" element= { <Resume /> } />
            <Route path="/projects" element= { <Projects /> } />
            <Route path="/articles" element={ <Articles />} />
            <Route path="/articles/create" element={<ArticleForm />} />
            <Route path="/articles/:articleId/edit" element={<ArticleForm />} />
            <Route path="*" element={ <NotFound />} />
            <Route path="/articles/:articleId" element={ <Article />} />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
